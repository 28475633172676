import {Splide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {renderSlides} from "../utils/slides";

const PhysicsPage = () => {
  const SLIDES_COUNT = 8;

  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Physics - formulas — chernykh.tech</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/physic-icon.webp')}`}/>
                <img src={require('../assets/images/physic-icon.jpg')}
                     alt=""
                     className="app-page__icon"/>
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Physics — <span className="orange">formulas</span></h2>
              <div className="app-page__stats">
                <span className="app-page__stats-count app-page__stats-count--downloads">500 000+</span>
                <span className="app-page__stats-count app-page__stats-count--feedback">7 000+</span>
              </div>
            </div>
          </div>
          <div className="app-page__links">
            <a href="https://play.google.com/store/apps/details?id=august.fizika"
               className="app-page__download-link app-page__download-link--android" aria-label="Google Play Link"
               target="_blank" rel="noreferrer">
              <img src={require('../assets/images/google-play-light-badge.svg').default} alt=""/>
            </a>
            <a href="https://apps.apple.com/app/id1495587959"
               className="app-page__download-link app-page__download-link--ios" aria-label="App Store Link"
               target="_blank" rel="noreferrer">
              <img src={require('../assets/images/appstore-light-badge.svg').default} alt=""/>
            </a>
          </div>
          <p className="app-page__lead">
            <span className="orange">Physics</span> is a beautiful and functional app for your phone, with all the
            chemical laws and terms gathered in one place.
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              1279: {
                perPage: 2,
                arrows: false
              }
            }
          }}>

            {renderSlides(SLIDES_COUNT, 'physics')}
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            Physics.Formulas is a reference book of terms, formulas, and tables from the school curriculum for grades
            7-11.
            In the guide you will meet the theory, terms and formulas. <br/>
            Download the app and you will prepare for the USE, USE and Physics Olympiads. Study from your cell phone or
            tablet, without the internet!
          </p>
          <br/>
          <p className="app-page__description">
            We have collected for you: <br/>
            - More than 250 formulas c sorted by category. <br/>
            - Easy in-app navigation <br/>
            - 280+ terms, words or phrases that are restrictive <br/>
            - Dozens of interesting tables that help you: Informally learn the topic, work consciously with
            learning information, independently eliminate the gaps in your knowledge <br/>
            - 180+ calculators that you'll use in every lesson to solve any example, equation, or problem <br/>
            And more…
          </p>
          <br/>
        </div>
      </section>
    </>
  );
};

export default PhysicsPage;