import {Splide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {renderSlides} from "../utils/slides";

const PtWebPage = () => {
  const SLIDES_COUNT = 6;

  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Periodic table WEB — checnykh.tech</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/pt-icon@1x.webp')} 1x,
              ${require('../assets/images/pt-icon@2x.webp')} 2x`} />
                <img src={require('../assets/images/pt-icon@1x.jpg')}
                     srcSet={`${require('../assets/images/pt-icon@2x.jpg')} 2x`}
                     alt=""
                     className="app-page__icon" />
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Periodic Table - <span className="yellow">Web</span></h2>
              <div className="app-page__stats">
                <a className="app-page__stats-count app-page__stats-count--link" href="https://periodic-table.tech/" target="_blank" rel="noreferrer">periodic-table.tech</a>
              </div>
            </div>
          </div>
          <p className="app-page__lead">
            <span className="red">Periodic Table for big screens!</span> Now you can study Chemistry on your computer,
            follow the link - periodic-table.tech from your computer and start studying Chemistry on the big screen.
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 2,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              767: {
                perPage: 1,
                arrows: false
              }
            }
          }}>
            {renderSlides(SLIDES_COUNT, 'pt-web')}
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            We have created a convenient and beautiful site for you so that you are not distracted by your phone
            while writing an outline or preparing for an exam. You can study Chemistry in large groups with a projector,
            the whole class, the group, will see the required Chemical elements and the properties being studied.
          </p>
          <br />
          <p className="app-page__description">
            With a wealth of information about the Chemical Elements and a user-friendly interface,
            learning Chemistry takes it to the next level, just click on a chemical element and the app will show
            you all the information available about it.
          </p>
          <br />
          <p className="app-page__description">
            Go to the site and you will learn: that Antimony has an atomic radius - 133pm, electronegativity
            Germany - 2.01. Tellurium has a boiling point - 990°C, Aluminum - 2518.82°C and much more just
            click on the element)
          </p>
          <br />
          <p className="app-page__description">
            With the app, you'll have no problem answering the questions: How much is Iridium or Phosphorus?
            Which of these has a higher density - Rubidium, Tungsten, Gold or Helium. What kind of crystal lattice
            it has - Dubnium, Siborium and Strontium. By whom and in what year was it discovered - Nickel,
            Polonium, Indium? What does Potassium, Thallium or Gallium look like?
            Periodic Table has answers to these and thousands of other questions.
          </p>
        </div>
      </section>
    </>
  );
};

export default PtWebPage;