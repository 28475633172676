import {Splide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {renderSlides} from "../utils/slides";

const PtMobilePage = () => {
  const SLIDES_COUNT = 6;
  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Periodic Table Mobile — chernykh.tech</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/pt-icon@1x.webp')} 1x,
              ${require('../assets/images/pt-icon@2x.webp')} 2x`}/>
                <img src={require('../assets/images/pt-icon@1x.jpg')}
                     srcSet={`${require('../assets/images/pt-icon@2x.jpg')} 2x`}
                     alt=""
                     className="app-page__icon"/>
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Periodic Table - <span className="red">Mobile</span></h2>
              <div className="app-page__stats">
                <span className="app-page__stats-count app-page__stats-count--downloads">7 000 000 +</span>
                <span className="app-page__stats-count app-page__stats-count--feedback">423 000 +</span>
              </div>
            </div>
          </div>
          <div className="app-page__links">
            <div className="app-page__download-links">
              <a href="https://play.google.com/store/apps/details?id=mendeleev.redlime"
                 className="app-page__download-link app-page__download-link--android" aria-label="Google Play Link"
                 target="_blank" rel="noreferrer">
                <img src={require('../assets/images/google-play-light-badge.svg').default} alt=""/>
              </a>
              <a href="http://itunes.apple.com/app/id1451726577"
                 className="app-page__download-link app-page__download-link--ios" aria-label="App Store Link"
                 target="_blank" rel="noreferrer">
                <img src={require('../assets/images/appstore-light-badge.svg').default} alt=""/>
              </a>
            </div>
            <a href="/faq" className="app-page__faq-link">FAQ</a>
          </div>
          <p className="app-page__lead">
            <span className="red">Periodic Table</span> - is a simple and convenient app with a huge number of functions
            and information about the chemical elements on your phone!
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              1279: {
                perPage: 2,
                arrows: false
              }
            }
          }}>
            {renderSlides(SLIDES_COUNT, 'pt-mobile')}
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            The Mendeleev Table is more than 150 years old, but year after year it's an indispensable aid when studying
            chemistry. <br/> We have collected a huge amount of information about the chemical elements and created
            a convenient and beautiful app for your phone, download it on Google Play and AppStore!
          </p>
          <br/>
          <p className="app-page__description">
            Chemistry is the science of chemical elements, their compounds, and the transformations that result from
            chemical reactions. <br/> Chemistry studies what things and substances are made of; why and how iron rusts
            and why tin does not rust; what happens to food in the body; why a solution of salt conducts electric
            current while a solution of sugar does not; why some chemical changes happen quickly while others
            happen slowly. <br/> How chemical plants turn coal, oil, ores, water, and air oxygen into detergents
            and dyes, plastics and polymers, drugs and metal alloys, fertilizers, herbicides, and insecticides.
          </p>
          <br/>
          <p className="app-page__description">
            Periodic Table - is an application that will help you dive into the huge and fascinating world of Chemistry.
            It contains a huge amount of information that will be useful to you even if you do not study chemistry.
          </p>
          <br/>
          <p className="app-page__description">
            Download the app from Google Play or the App Store and you will learn: that Antimony has an atomic
            radius - 133pm, electronegativity Germany - 2.01. Tellurium has a boiling point - 990 ° C,
            Aluminum - 2518.82 ° C and much more - just click on the element)
          </p>
          <br/>
          <p className="app-page__description">
            With the app you will have no problem answering the questions: What is the atomic mass of Samarium or
            Polonium?<br/>
            What is the melting point of Rubidium, what is its RTEC and CAS number?<br/>
            In what year and by whom were Bromium, Gold, Sulfur, and Livermorium discovered?<br/>
            What does Bismuth, Molybdenum, Indium, or Argon look like?<br/>
            Periodic Table - has answers to thousands of chemistry questions
          </p>
        </div>
      </section>
    </>
  );
};

export default PtMobilePage;