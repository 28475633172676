import {SplideSlide} from "@splidejs/react-splide";

export const renderSlides = (slidesCount, name) => {
  const slides = [];
  for (let i = 1; i <= slidesCount; i++) {
    slides.push(
      <SplideSlide key={i}>
        <picture>
          <source type="image/webp" srcSet={require(`../assets/images/screens/${name}/${i}.webp`)} />
          <img src={require(`../assets/images/screens/${name}/${i}.jpg`)} alt="" />
        </picture>
      </SplideSlide>
    );
  }
  return slides;
};